<template>
  <div class="main-content">
    <div class="main-title">编辑赛事主题</div>
    <div class="from">
      <div class="row">
        <div class="title">赛事主题名称</div>
        <input type="text" placeholder="请输入赛事主题名称" v-model.trim="raceObj.name">
      </div>
      <div class="row">
        <div class="title">赛事参赛封面</div>
        <div class="template2">
          <label for="def_input2">
            <img class="template2" :src="raceObj.join_img || require('@/assets/upload_black_icon.png')" alt=" ">
          </label>
          <input type="file" accept="image/*" hidden id="def_input2" @change="uploadImg2">
        </div>
      </div>
      <div class="row">
        <div class="title">赛事主题封面</div>
        <div class="template">
          <label for="def_input">
            <img class="template" :src="raceObj.img || require('@/assets/upload_black_icon.png')" alt=" ">
          </label>
          <input type="file" accept="image/*" hidden id="def_input" @change="uploadImg">
        </div>
      </div>
      <div class="row">
        <div class="title">赛事文章标题《中文》</div>
        <input type="text" placeholder="请输入文档标题《中文》" v-model.trim="raceObj.article_title_zh">
      </div>
      <div class="row">
        <div class="title">赛事文章标题《英文》</div>
        <input type="text" placeholder="请输入文档标题《中文》" v-model.trim="raceObj.article_title_en">
      </div>
      <div class="row">
        <div class="title">赛事文章图</div>
        <div class="template3">
          <label for="def_input3">
            <img class="template3" :src="raceObj.article_img || require('@/assets/upload_black_icon.png')" alt=" ">
          </label>
          <input type="file" accept="image/*" hidden id="def_input3" @change="uploadImg3">
        </div>
      </div>
      <div class="row">
        <div class="title">赛事文章内容</div>
        <template>
          <vue-tinymce
              v-model="raceObj.article_text"
              :setting="setting" />
        </template>
      </div>
      <div class="row">
        <div class="title">赛事协议内容</div>
        <template>
          <vue-tinymce
              v-model="raceObj.article_agreement"
              :setting="setting" />
        </template>
      </div>
      <div class="btn-box">
        <span class="btn-confirm" @click="edit">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import {editWorkRaceTopic,getWorkRaceTopicInfo} from "@/request/api";
import {Warning} from "@/plugins/warning";
import $ from "jquery";
import OSS from "ali-oss";
import { LoadingFn } from '@/plugins/loading';

export default {
  name: "article_edit",
  components:{
  },
  data() {
    return {
      id:0,
      race_id:0,
      img:"",
      raceObj:{},
      setting: {
        menubar: true,
        toolbar: "undo redo | code | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "code link image media table lists fullscreen quickbars",
        language: 'zh_CN', //本地化设置
        language_url: '../../plugins/zh_CN.js',
        height: 600,
        images_upload_handler: (blobInfo, success, failure) => {

          //const that = this;
          const file = blobInfo.filename();
          let filename=file.replace(/.*(\/|\\)/, "");
          let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
          let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
          $.ajax({
            url: process.env.VUE_APP_SERVER_URL+'/file/upload',
            data:{
              suffix:fileExt[0],
              path:"config"
            },
            headers:{
              Authorization:storage.infoModule.token
            },
            async: true,
            global: false,
            type: 'POST',
            dataType: 'json',
            timeout:1000*7,
            success: function(data) {
              if (data.status === 200) {
                const client = new OSS({
                  region: data.result.data.region,
                  accessKeyId: data.result.data.access_key_id,
                  accessKeySecret: data.result.data.access_key_secret,
                  stsToken: data.result.data.security_token,
                  bucket: data.result.data.bucket,
                });

                client.multipartUpload(data.result.data.key, blobInfo.blob(), {
                  // 设置上传回调。
                  progress:(p) => {
                    if(p>=1){
                      //console.log(data.result.data)
                      //that.thumb = data.result.data.url
                      //that.articleObj.thumb_img = data.result.data.full_url

                      success(data.result.data.full_url)
                    }
                  }
                });
              }else {
                failure("error");
              }
            }
          })

        }
      }
    }
  },
  computed: {

  },
  created() {
    this.id = this.$route.query.id;
    if(this.id > 0){
      this.getInfo();
    }else{
      this.race_id = this.$route.query.race_id;
    }
  },
  mounted() {

  },
  methods: {
    edit(){
      if(this.id == undefined){
        this.id = 0;
      }
      const requestData = {
        "id":this.id,
        "race_id":this.race_id,
        "name":this.raceObj.name,
        "img":this.raceObj.img,
        "join_img":this.raceObj.join_img,
        "article_img":this.raceObj.article_img,
        "article_text":this.raceObj.article_text,
        "article_agreement":this.raceObj.article_agreement,
        "article_title_zh":this.raceObj.article_title_zh,
        "article_title_en":this.raceObj.article_title_en,
      };

      editWorkRaceTopic(requestData).then(()=>{
        Warning.success("操作成功");
      })
    },
    getInfo(){
      const requestData = {"id":this.id};
      getWorkRaceTopicInfo(requestData).then((res)=>{
        this.raceObj = res.data;
        this.race_id = this.raceObj.race_id;
      })
    },
    uploadImg(e){
      LoadingFn.open();
      const that = this;
      const file = e.target.value;
      let filename=file.replace(/.*(\/|\\)/, "");
      let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
      let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
      $.ajax({
        url: process.env.VUE_APP_SERVER_URL+'/file/upload',
        data:{
          suffix:fileExt[0],
          path:"works"
        },
        headers:{
          Authorization:storage.infoModule.token
        },
        async: true,
        global: false,
        type: 'POST',
        dataType: 'json',
        timeout:1000*7,
        success: function(data) {
          if (data.status === 200) {
            const client = new OSS({
              region: data.result.data.region,
              accessKeyId: data.result.data.access_key_id,
              accessKeySecret: data.result.data.access_key_secret,
              stsToken: data.result.data.security_token,
              bucket: data.result.data.bucket,
            });

            client.multipartUpload(data.result.data.key, e.target.files[0], {
              // 设置上传回调。
              progress:(p) => {
                if(p>=1){
                  setTimeout(()=>{
                    that.img = data.result.data.url
                    that.raceObj.img = data.result.data.full_url
                    LoadingFn.close();
                  },1000)
                }
              }
            });
          }else {
            Warning.open(data.msg);
          }
        }
      })
    },
    uploadImg2(e){
      LoadingFn.open();
      const that = this;
      const file = e.target.value;
      let filename=file.replace(/.*(\/|\\)/, "");
      let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
      let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
      $.ajax({
        url: process.env.VUE_APP_SERVER_URL+'/file/upload',
        data:{
          suffix:fileExt[0],
          path:"works"
        },
        headers:{
          Authorization:storage.infoModule.token
        },
        async: true,
        global: false,
        type: 'POST',
        dataType: 'json',
        timeout:1000*7,
        success: function(data) {
          if (data.status === 200) {
            const client = new OSS({
              region: data.result.data.region,
              accessKeyId: data.result.data.access_key_id,
              accessKeySecret: data.result.data.access_key_secret,
              stsToken: data.result.data.security_token,
              bucket: data.result.data.bucket,
            });

            client.multipartUpload(data.result.data.key, e.target.files[0], {
              // 设置上传回调。
              progress:(p) => {
                if(p>=1){
                  setTimeout(()=>{
                    that.join_img = data.result.data.url
                    that.raceObj.join_img = data.result.data.full_url
                    LoadingFn.close();
                  },1000)
                }
              }
            });
          }else {
            Warning.open(data.msg);
          }
        }
      })
    },
    uploadImg3(e){
      LoadingFn.open();
      const that = this;
      const file = e.target.value;
      let filename=file.replace(/.*(\/|\\)/, "");
      let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
      let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
      $.ajax({
        url: process.env.VUE_APP_SERVER_URL+'/file/upload',
        data:{
          suffix:fileExt[0],
          path:"works"
        },
        headers:{
          Authorization:storage.infoModule.token
        },
        async: true,
        global: false,
        type: 'POST',
        dataType: 'json',
        timeout:1000*7,
        success: function(data) {
          if (data.status === 200) {
            const client = new OSS({
              region: data.result.data.region,
              accessKeyId: data.result.data.access_key_id,
              accessKeySecret: data.result.data.access_key_secret,
              stsToken: data.result.data.security_token,
              bucket: data.result.data.bucket,
            });

            client.multipartUpload(data.result.data.key, e.target.files[0], {
              // 设置上传回调。
              progress:(p) => {
                if(p>=1){
                  setTimeout(()=>{
                    that.article_img = data.result.data.url
                    that.raceObj.article_img = data.result.data.full_url
                    LoadingFn.close();
                  },1000)
                }
              }
            });
          }else {
            Warning.open(data.msg);
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > input{
        height: 50px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding-left: 15px;
        padding-right: 15px;
      }
      & > textarea{
        height: 500px;
        min-width: 800px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding: 15px;
      }
      .el-select{
        margin-right: 15px;
      }
      .address{
        margin-top: 15px;
        min-width: 600px;
      }
      .template{
        width: 300px;
        height: 200px;
        label {
          display: block;
          width: 100%;
          height: 100%;
          & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
            margin-top: 15px;
            border: 0.5px solid $blockColor--1;
          }
        }
      }
      .template2{
        width: 300px;
        height: 200px;
        label {
          display: block;
          width: 100%;
          height: 100%;
          & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
            margin-top: 15px;
            border: 0.5px solid $blockColor--1;
          }
        }
      }
      .template3{
        width: 500px;
        height: 200px;
        label {
          display: block;
          width: 100%;
          height: 100%;
          & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
            margin-top: 15px;
            border: 0.5px solid $blockColor--1;
          }
        }
      }
    }
    .thumb-box{
      width: 300px;
      height: 200px;
      position: absolute;
      top:0;
      right: 30px;
      label {
        display: block;
        width: 100%;
        height: 100%;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          cursor: pointer;
          margin-top: 15px;
          border: 0.5px solid $blockColor--1;
        }
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
  }
}
</style>
